import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import PageNotFound from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/luo-tunnus/:id',
		name: 'Luo tunnus',
		component: Register
	},
	/* manager */
	{
		path: '/ohjausnakyma',
		name: 'Ohjausnäkymä',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
	},
	{ path: '/hallinta', redirect: '/hallinta/kohteet' },
	{
		path: '/hallinta/kohteet',
		name: 'Kohteet',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "sites" */ '../views/Sites.vue')
	},
	{
		path: '/hallinta/sopimusasiakkaat',
		name: 'Sopimusasiakkaat',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue')
	},
	{
		path: '/hallinta/asukkaat',
		name: 'Asukkaat',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
	},
	{
		path: '/hallinta/huoltomiehet',
		name: 'Huoltomiehet',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "employees" */ '../views/Employees.vue')
	},
	{
		path: '/tiedottaminen',
		name: 'Tiedottaminen',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "bulletins" */ '../views/Bulletins.vue')
	},
	{
		path: '/huoltoilmoitukset',
		name: 'Huoltoilmoitukset',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue')
	},
	{
		path: '/raportointi',
		name: 'Raportointi',
		meta: { usergroups: ['Manager'] },
		component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
	},
	/* user */
	{
		path: '/kohteet',
		name: 'Kohteet huolto',
		meta: { usergroups: ['Manager-employee'] },
		component: () => import(/* webpackChunkName: "user-bulletins" */ '../views/UserSites.vue')
	},
	{
		path: '/tiedotteet',
		name: 'Tiedotteet',
		meta: { usergroups: ['Resident', 'Property-manager'] },
		component: () => import(/* webpackChunkName: "user-bulletins" */ '../views/UserBulletins.vue')
	},
	{
		path: '/omat-huoltoilmoitukset',
		name: 'Omat-huoltoilmoitukset',
		meta: { usergroups: ['Resident', 'Property-manager', 'Manager-employee'] },
		component: () => import(/* webpackChunkName: "user-tickets" */ '../views/UserTickets.vue')
	},
	{
		path: '/omat-tiedot',
		name: 'Omat-tiedot',
		meta: { usergroups: ['Resident', 'Property-manager', 'Manager-employee', 'Manager'] },
		component: () => import(/* webpackChunkName: "user-profile" */ '../views/UserProfile.vue')
	},
	{
		path: '/vaihda-salasana',
		name: 'Vaihda-salasana',
		meta: { usergroups: ['Manager', 'Manager-employee', 'Resident', 'Property-manager'] },
		component: () => import(/* webpackChunkName: "change-pass" */ '../views/ChangePassword.vue')
	},

	{ path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

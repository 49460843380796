<template>
	<div class="container">
		<div class="row">
			<div class="col-12 py-5">

				<b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

				<h1>Rekisteröidy kohteeseen {{ site.name }}</h1>

				<form v-if="showform" class="profile register" @submit.prevent="registerSend">

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Sähköposti:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.email" required />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Salasana:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.password" required />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Nimi:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.fullname" required />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Osoite:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.address" required />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Asuntonumero:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.city" required />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Puhelin:</label>
						<div class="col-sm-9 pr-0">
							<input type="text" class="form-control" v-model="regform.phone" />
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-3 col-form-label">Rooli:</label>
						<div class="col-sm-9 pr-0">
							<select class="form-control" v-model="regform.fax">
								<option>vuokralainen</option>
								<option>alivuokralainen</option>
								<option>omistaja</option>
							</select>
						</div>
					</div>

					<div class="check-wrap" >
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="terms" v-model="regform.terms" required>
							<label class="custom-control-label" for="terms">Hyväksyn käyttöehdot</label>
						</div>
					</div>

					<p class="mt-4">Käyttöehdot</p>

					<button type="submit" class="btn btn-primary">Rekisteröidy</button>

				</form>

				<b-alert class="my-3" variant="info" :show="error? true : false" v-html="error"></b-alert>

			</div>
		</div>
	</div>
</template>
<script>

module.exports = {
	data: function () {
		return {
			loading: true,
			error: false,
			sending: false,
			showform: false,
			site: { name: '' },
			regform: {
				site_id: 0,
				firstname: '',
				lastname: '',
				address: '',
				city: '',
				fax: 'vuokralainen',
				phone: '',
				email: '',
				password: '',
				terms: false
			}
		}
	},
	created: function () {
		var vm = this;

		this.axios.get('/api/register/' + vm.$route.params.id)
		.then(function (response) {
			if(response.data.success) {
				vm.loading = false;
				vm.regform.site_id = response.data.object.id;
				vm.site = response.data.object;
				vm.showform = true;
			} else {
				vm.loading = false;
				vm.error = 'Tarkista osoite!';
			}
		})
		.catch(function (error) {
			console.log(error);
			vm.loading = false;
			vm.error = 'Tarkista osoite!';
		})
	},
	methods: {
		registerSend: function () {
			var vm = this;
			if(vm.regform.site_id == 0 || vm.regform.email == '' || vm.regform.fullname == '') {
				vm.error = 'Täytä kaikki kentät!';
			} else if(!vm.sending) {
				vm.sending = true;
				this.axios.post('/api/register', vm.regform)
				.then(function (response) {

					if(response.data.success) {
						vm.error = 'Rekisteröityminen onnistui. Saat tiedon sähköpostiisi, kun käyttäjätilisi on aktivoitu.';
						vm.sending = false;
						vm.showform = false;
					} else {
						var tmp = []
						Object.keys(response.data.errors).forEach(function(key){ tmp.push(response.data.errors[key]); });
						vm.error = tmp.join("<br>", tmp);
						vm.sending = false;
					}

				})
				.catch(function (error) {
					vm.error = error;
					vm.sending = false;
				})
			}
		}
	}
}

</script>

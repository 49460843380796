<template>
	<div id="app" :class="[$parent.usergroup == 'Manager' ? 'mgr-view' : '']">

		<div id="top-wrap" v-if="$parent.usergroup == 'Manager'">
			<div class="container">
				<div class="row">
					<div class="col-12 text-right py-2 login-info">
						<p class="mb-0">{{$parent.fullname}} <router-link to="/omat-tiedot"><span class="mx-4">{{$parent.username}}</span></router-link> <router-link to="/?logout=true">Kirjaudu ulos</router-link></p>
					</div>
				</div>
			</div>
		</div>
		<div id="menu-wrap" v-if="$parent.usergroup == 'Manager'">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<router-link class="logo" to="/">Kanava</router-link>
						<div id="nav">
							<router-link v-if="$parent.usergroup == 'Manager'" to="/ohjausnakyma"><i class="fas fa-layer-group"></i> Ohjausnäkymä</router-link>
							<router-link v-if="$parent.usergroup == 'Manager'" to="/tiedottaminen"><i class="fas fa-comment-alt-exclamation"></i> Tiedottaminen</router-link>
							<router-link v-if="$parent.usergroup == 'Manager'" to="/huoltoilmoitukset"><i class="fas fa-envelope-open-text"></i> Huoltoilmoitukset</router-link>
							<router-link v-if="$parent.usergroup == 'Manager'" to="/hallinta"><i class="fas fa-city"></i> Kohteiden hallinta</router-link>
							<router-link v-if="$parent.usergroup == 'Manager'" to="/raportointi"><i class="fas fa-chart-bar"></i> Raportointi</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-overlay v-if="showmenu" variant="dark" z-index="11" show no-wrap><template #overlay><p>&nbsp;</p></template></b-overlay>
		<div id="user-top-wrap" v-if="$parent.usergroup != '' && $parent.usergroup != 'Manager'">
			<div class="container-fluid yellow">
				<div class="row">
					<div class="col-12 py-2 login-info">
						<p class="blue mb-0" v-if="$parent.usergroup != 'Manager-employee'">{{ $parent.getSite() }} <i class="fas fa-chevron-down ml-1 cursor-pointer" v-if="$parent.sites.length > 1" v-on:click="selectSite(0)"></i></p>
						<p class="blue mb-0" v-if="$parent.usergroup == 'Manager-employee'">{{ $parent.fullname }}</p>
					</div>
				</div>
			</div>
			<div class="container-fluid blue">
				<div class="row">
					<div class="col-12" :class="[showmenu ? 'menu-open' : 'menu-closed']">
						<router-link class="logo" to="/">Kanava</router-link>
						<button v-if="$parent.usergroup != 'Manager-employee'" class="menu-toggle" v-on:click="toggleMenu"><span><i class="fas fa-bars" v-if="!showmenu"></i><i class="fas fa-times" v-if="showmenu"></i></span></button>
						<button v-if="$parent.usergroup == 'Manager-employee'" class="menu-toggle" ><router-link to="/?logout=true"><span><i class="fas fa-sign-out"></i></span></router-link></button>
						<div class="nav" v-if="$parent.usergroup != 'Manager-employee'">
							<router-link class="logo" to="/">Kanava</router-link>
							<p class="wborder"><strong>{{ $parent.getSite() }}, {{$parent.apartment}} <i class="fas fa-chevron-down ml-1 cursor-pointer" v-if="$parent.sites.length > 1" v-on:click="selectSite(0)"></i></strong><br/>{{$parent.fullname}}</p>
							<ul>
								<li><router-link to="/tiedotteet"><i class="fas fa-comment-alt-exclamation"></i> Tiedotteet</router-link></li>
								<li><router-link to="/omat-huoltoilmoitukset"><i class="fas fa-tools"></i> Huoltoilmoitukset</router-link></li>
								<li><router-link to="/omat-huoltoilmoitukset?id=new" class="noactive"><i class="fas fa-plus-circle"></i> Uusi huoltoilmoitus</router-link></li>
								<li><router-link to="/omat-tiedot"><i class="fas fa-user"></i> Omat tiedot</router-link></li>
								<li><router-link to="/?logout=true"><i class="fas fa-sign-out"></i> Kirjaudu ulos</router-link></li>
							</ul>
							<p class="text-center user-section mt-4 mb-0"><a class="btn btn-success" href="#"><i class="fas fa-phone"></i>SOITA - PÄIVYSTYS 24/7</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<router-view/>

		<b-overlay v-if="showsiteselect && $parent.usergroup != '' && $parent.usergroup != 'Manager'" variant="dark" z-index="15" show no-wrap>
			<template #overlay>
				<div id="user-select-site">
					<div class="list-group">
						<a href="#" class="list-group-item list-group-item-action" v-for="site in $parent.sites" :key="site.id" :class="[ site.id == $parent.current_site ? 'active' : '' ]" v-on:click="selectSite(site.id)">{{ site.name }}</a>
					</div>
				</div>
			</template>
		</b-overlay>

		<div class="footer-nav"  v-if="$parent.usergroup != '' && $parent.usergroup != 'Manager'">
			<ul>
				<li v-if="$parent.usergroup != 'Manager-employee'"><router-link to="/tiedotteet"><i class="fas fa-comment-alt-exclamation"></i> Tiedotteet</router-link></li>
				<li><router-link to="/omat-huoltoilmoitukset"><i class="fas fa-tools"></i> Huolto</router-link></li>
				<li v-if="$parent.usergroup == 'Manager-employee'"><router-link to="/kohteet"><i class="fas fa-city"></i> Kohteet</router-link></li>
				<li><router-link to="/omat-tiedot"><i class="fas fa-user"></i> Omat tiedot</router-link></li>
			</ul>
		</div>

	</div>
</template>
<script>
  export default {
		data: function () {
			return {
				showmenu: false,
				showsiteselect: false
			}
		},
    methods: {
      makeToast(variant = null, title, body) {
        this.$bvToast.toast(body, {
          title: title,
          variant: variant,
          solid: true
        })
      },
			toggleMenu: function() {
				if(this.showmenu) this.showmenu = false;
				else this.showmenu = true;
			},
			selectSite: function(id) {
				if(id === 0) {
					this.showsiteselect = true;
					//this.showmenu = false;
				} else {
					this.$parent.current_site = id;
					this.showsiteselect = false;
					//if(this.$route.fullPath != '/tiedotteet') this.$router.push('/tiedotteet');
				}
			}
    },
		watch: {
			'$route' () {
				this.showmenu = false;
			}
		}
  }
</script>

<template>

  <div class="container login ">
		<div class="row">
			<div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center login-box py-5">

        <b-overlay v-if="loading" variant="white" show no-wrap></b-overlay>

        <div v-if="userid == 0" class="login-logo"></div>

				<h1 v-if="!forgot">Kirjaudu sisään</h1>
				<h1 v-if="forgot">Forgot your password?</h1>
				<b-alert v-html="error" :show="error? true : false"></b-alert>

				<form v-if="userid == 0">

					<b-form-group id="input-email" label="Sähköpostiosoite" label-for="input-user" >
						<b-form-input id="input-user" v-model="username" type="text" placeholder=""></b-form-input>
					</b-form-group>

					<b-form-group id="input-pass" label="Salasana" label-for="input-pass" v-if="!forgot">
						<b-form-input id="input-pass" v-model="password" type="password" placeholder="" v-on:keyup.enter="login"></b-form-input>
					</b-form-group>

					<button v-if="!forgot" v-on:click="login" type="button" class="btn btn-primary">Kirjaudu sisään</button>

					<button v-if="forgot" v-on:click="forgotpass" type="button" class="btn btn-primary">Send me a new password</button>
					<p v-if="forgot"><a href="#" v-on:click.stop.prevent="forgot = false">Cancel</a></p>

          <p class="my-4"><a href="/unohditko-salasanasi">Unohditko salasanasi?</a></p>

				</form>

				<b-alert :show="userid > 0? true : false">Olet kirjautunut sisään tunnuksella {{ $parent.$parent.username }}.</b-alert>
				<button v-if="userid > 0" v-on:click="logout" type="button" class="btn btn-secondary">Kirjaudu ulos</button>

				<div class="row text-center mt-2" v-if="0">
					<div class="col-12">
						<p v-if="!forgot && userid == 0"><br/><a href="#" v-on:click.stop.prevent="forgot = true">Forgot your password?</a></p>
					</div>
				</div>

			</div>
		</div>
	</div>

</template>

<script>
module.exports = {
	data: function () {
		return {
			error: false,
			forgot: false,
      loading: false,
			username: '',
			password: ''
		}
	},
	computed: {
		userid: function() {
			return this.$parent.$parent.userid;
		}
	},
	created: function () {
		var vm = this;
		if(typeof vm.$route.query.logout != 'undefined') {
			vm.logout();
		}
	},
	methods: {
		login: function () {
			var vm = this;
			if(vm.password == '' || vm.username == '') {
				vm.error = 'Syötä käyttäjätunnus ja salasana!';
			} else {
        vm.loading = true;
				this.axios.post('/api/auth', 'username='+vm.username+'&password='+vm.password)
				.then(function (response) {

					if(response.data.success) {
            vm.$parent.$parent.setData(response.data.object);
						vm.username = '';
						vm.password = '';
						vm.error = '';

            if(!response.data.object.changepass && response.data.object.group == 'Manager') vm.$router.push('/ohjausnakyma');
            if(!response.data.object.changepass && response.data.object.group == 'Resident') vm.$router.push('/tiedotteet');
            if(!response.data.object.changepass && response.data.object.group == 'Property-manager') vm.$router.push('/tiedotteet');
            if(!response.data.object.changepass && response.data.object.group == 'Manager-employee') vm.$router.push('/omat-huoltoilmoitukset');

					} else {
						vm.error = response.data.message;
					}
          vm.loading = false;
				})
				.catch(function (error) {
					vm.error = error;
          vm.loading = false;
				})
			}
		},
		logout: function () {
			var vm = this;
      vm.loading = true;
			this.axios.post('/api/auth', 'logout=true')
			.then(function (response) {

				if(response.data.success) {
					vm.$parent.$parent.loggedin = false;
					vm.$parent.$parent.userid = 0;
					vm.$parent.$parent.username = '';
					vm.$parent.$parent.fullname = '';
          vm.$parent.$parent.email = '';
          vm.$parent.$parent.phone = '';
          vm.$parent.$parent.apartment = '';
          vm.$parent.$parent.sites = [];
          vm.$parent.$parent.current_site = 0;
					vm.$parent.$parent.usergroup = '';
					vm.$parent.$parent.token = '';
          if(vm.$route.fullPath != '/') vm.$router.push('/');
				} else {
					vm.error = response.data.message;
				}
        vm.loading = false;
			})
			.catch(function (error) {
				vm.error = error;
        vm.loading = false;
			})
		},
		forgotpass: function () {
			var vm = this;
			if(vm.username == '') {
				vm.error = 'Enter username!';
			} else {
				this.axios.post('/api/auth/forgotpass', 'username='+vm.username)
				.then(function (response) {

					if(response.data.success) {
						vm.error = response.data.message;
						vm.forgot = false;
					} else {
						vm.error = response.data.message;
					}

				})
				.catch(function (error) {
					vm.error = error;
				})
			}
		}
	}
}
</script>

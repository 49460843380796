import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css';

import './assets/fontawesome-pro-5.6.1-web/css/fontawesome.min.css'
import './assets/fontawesome-pro-5.6.1-web/css/solid.min.css'
import './assets/css/style.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.axios.interceptors.request.use(
  (config) => {
    let token = app.token;

    if (token) {
      config.headers['Authorization'] = `P-token ${ token }`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false

import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY H:mm')
  }
})

Vue.filter('formatInputDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DDTHH:mm')
  }
})

Vue.filter('formatDateTimePretty', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY [klo] H:mm')
  }
})


const app = new Vue({
	router,
	render: h => h(App),
	data: {
		loggedin: false,
		userid: 0,
		username: '',
		fullname: '',
    email: '',
    phone: '',
    apartment: '',
    sites: [],
    current_site: 0,
		usergroup: '',
    changepass: false,
		token: ''
	},
	created: function () {
		this.checklogin();
	},
	methods: {
		checklogin: function () {
			var vm = this;
			this.axios.get('/api/auth')
			.then(function (response) {
				if(response.data.success) {
          vm.setData(response.data.object);
          //console.log(vm.$route.fullPath)
          /*if(vm.$route.fullPath != '/') {
            var path = vm.$route.fullPath
            router.push('/');
            router.push(path);
          }*/
				} else {
					if(vm.$route.fullPath != '/' && vm.$route.fullPath.split("/")[1] != 'luo-tunnus') router.push('/');
				}
				app.$mount('#app');
			})
			.catch(function (error) {
				console.log(error);
				app.$mount('#app');
			})
		},
    setData: function(object) {
      var vm = this;
      vm.loggedin = true;
      vm.userid = object.id;
      vm.username = object.username;
      vm.fullname = object.fullname;
      vm.email = object.email;
      vm.phone = object.phone;
      vm.apartment = object.apartment;
      vm.sites = object.sites;
      vm.usergroup = object.group;
      vm.changepass = object.changepass;
      vm.token = object.token;

      if(object.sites.length > 0) {
        for (var prop in object.sites) {
          vm.current_site = object.sites[prop].id;
          break;
        }
      }

      if(vm.changepass) {
        if(vm.$route.fullPath != '/vaihda-salasana') router.push('/vaihda-salasana');
      }

    },
    getSite: function() {
      var vm = this;
      if(vm.current_site > 0) {
        for (var prop in vm.sites) {
          if(vm.current_site == vm.sites[prop].id) return vm.sites[prop].name;
        }
      }
      return '-';
    }
	}
})

//check route usergroups
router.beforeEach((to, from, next) => {
  //console.log(to);
  if(to.path != '/vaihda-salasana' && app.changepass) {
    next('/vaihda-salasana');
  } else if(typeof to.meta.usergroups != 'undefined') {
    //console.log(to.meta.usergroups.includes(app.usergroup));
    if(to.meta.usergroups.includes(app.usergroup)) next();
    else next('/');
  } else {
    next();
  }

})
